import React, { Fragment } from "react"
import Navbar from "../../components/NavbarEn"

const index = () => {
  return (
    <Fragment>
      <Navbar />
      ENGLISH VERSION TEST
    </Fragment>
  )
}

export default index
