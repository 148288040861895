import React, { Fragment } from "react"
import { slide as Menu } from "react-burger-menu"
import "../css/navbar.css"
import Logo from "../images/Logo.png"
import Gb from "../images/uk.svg"
import Srb from "../images/serbia.svg"
import { Link } from "gatsby"

const Navbar = () => {
  return (
    <Fragment>
      <Menu className="mobile-menu">
        <Link to="/" className="menu-item">
          Home
        </Link>
        <Link to="/o-nama" className="menu-item">
          About Us
        </Link>
        <Link to="/proizvodi" className="menu-item">
          Products
        </Link>
        <a className="menu-item">Career</a>
        <a className="menu-item">Contact</a>
        <div className="menu-item">
          <Link to="/EN">
            <img src={Gb} alt="" />
          </Link>
          <Link to="/">
            <img src={Srb} alt="" />
          </Link>
        </div>
      </Menu>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div>
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <Link to="/" className="navbar-item">
                Home
              </Link>
              <Link to="/o-nama" className="navbar-item">
                About Us
              </Link>
              <Link to="/proizvodi" className="navbar-item">
                Products
              </Link>
              <a className="navbar-item">Career</a>
              <a className="navbar-item">Contact</a>
              <Link to="/EN" className="navbar-language">
                <img src={Gb} alt="" />
              </Link>
              <Link to="/" className="navbar-language">
                <img src={Srb} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default Navbar
